/*
// .vehicle-picker-modal
*/
@import '../variables';
@import '../mixins/direction';
@import '../mixins/scheme';


.vehicle-picker-modal {}
.vehicle-picker-modal__panel {
    padding: 32px 32px 36px;
    display: none;
}
.vehicle-picker-modal__panel--active {
    display: block;
}
.vehicle-picker-modal__close {
    z-index: 2;
    position: absolute;
    padding: 16px;
    border: none;
    top: 0;
    border-radius: 0 2.5px 0 2.5px;
    background: transparent;
    fill: currentColor;
    transition:
        background .2s,
        color .2s;

    @include define-button-scheme($btn-muted-scheme);
    @include direction {
        #{$inset-inline-end}: 0;
    }

    svg {
        display: block;
    }
    &:active {
        transition-duration: 0s;
    }
    &:focus {
        outline: none;
    }
}
.vehicle-picker-modal__text {
    font-size: 15px;
    color: map_get($menu-scheme, opposite-alt);
    margin-bottom: 10px;
    margin-top: -10px;
}
.vehicle-picker-modal__add-button {
    min-height: 44px;
}
.vehicle-picker-modal__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    > *:not(:last-child) {
        @include direction {
            #{$margin-inline-end}: 12px;
        }
    }
}

@media (max-width: 479px) {
    .vehicle-picker-modal__panel {
        padding: 24px 24px 28px;
    }
    .vehicle-picker-modal__actions {
        margin-top: 32px;
    }
}
@media (max-width: 399px) {
    .vehicle-picker-modal__panel {
        padding: 20px 20px 24px;
    }
    .vehicle-picker-modal__actions {
        margin-top: 28px;
    }
}
