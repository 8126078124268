/*
// .section-header
*/
@import '../variables';
@import '../constants';
@import '../mixins/direction';
@import '../mixins/breakpoints';
@import '../mixins/arrow';


$local-group-button-height: 23px;
$local-group-button-padding-x-base: $local-group-button-height * $TAN-20;


.section-header {}
.section-header__body {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}
// title
.section-header__title {
    font-size: 20px;
    font-weight: $font-weight-bold;
    margin: 0;
    position: relative;
    top: 2px;
}
// spring
.section-header__spring {
    flex-grow: 1;
}
// divider
.section-header__divider {
    width: 100%;
    height: 2px;
    margin-top: 8px;
    background: map_get($body-scheme, divider);
}
// arrows
.section-header__arrows {
    display: flex;
}
.section-header__arrow--next {
    @include direction {
        #{$margin-inline-start}: (23px * $TAN-20) * -1 + 3px;
    }
}
// links
.section-header__links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    font-size: 14px;
    flex-wrap: wrap;
}
.section-header__links-item {
    @include direction {
        #{$padding-inline-end}: 18px;
    }

    &:last-child {
        @include direction {
            #{$padding-inline-end}: 0;
        }
    }
}
.section-header__links-link {
    color: map_get($card-scheme, opposite-alt);
    position: relative;
    transition: color .1s;
}
.section-header__links-link:hover {
    color: inherit;
}
.section-header__links + .section-header__arrows {
    @include direction {
        #{$margin-inline-start}: 18px;
    }
}
// groups
.section-header__groups {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}
.section-header__groups-item + .section-header__groups-item {
    @include direction {
        #{$margin-inline-start}: -$local-group-button-padding-x-base + 3px;
    }
}
.section-header__groups-button {
    font-size: 14px;
    height: $local-group-button-height;
    border: none;
    padding: 0 ($local-group-button-padding-x-base + 10px);
    line-height: $local-group-button-height;
    display: block;
    position: relative;
    z-index: 0;
    pointer-events: none;
    transition: color .1s;
    background: transparent;
    color: $section-header-group-font-color;
}
.section-header__groups-button:focus {
    outline: none;
}
.section-header__groups-button:after {
    position: absolute;
    display: block;
    content: '';
    width: calc(100% - #{$local-group-button-padding-x-base});
    height: 100%;
    top: 0;
    background: transparent;
    z-index: -1;
    pointer-events: auto;
    transition: background .1s;

    @include direction {
        #{$inset-inline-start}: 0;
        transform: skewX(#{-20deg * $transform-direction});
        transform-origin: $inline-start bottom;

        @include logical-border-radius(2px 2.5px);
    }
}
.section-header__groups-button:hover {
    color: $section-header-group-hover-font-color;

    &:after {
        background: $section-header-group-hover-bg-color;
    }
}
.section-header__groups-button:active {
    color: $section-header-group-active-font-color;

    &:after {
        background: $section-header-group-active-bg-color;
    }
}
.section-header__groups-button--active,
.section-header__groups-button--active:hover,
.section-header__groups-button--active:active {
    color: $section-header-group-current-font-color;
    cursor: default;

    &:after {
        background: $section-header-group-current-bg-color;
    }
}
.section-header__groups + .section-header__arrows {
    @include direction {
        #{$margin-inline-start}: (-$local-group-button-height * $TAN-20) + 3px;
    }
}


@include media-breakpoint-down(sm) {
    .section-header__body {
        flex-wrap: wrap;
    }
    .section-header__links {
        order: 1;
        width: 100%;
        padding: 9px 0 10px;
        border-bottom: 2px solid map_get($card-scheme, divider);
    }
    .section-header__links-item {
        padding-top: 1px;
        padding-bottom: 1px;
    }
    .section-header__groups {
        order: 1;
        width: 100%;
        border-bottom: 2px solid map_get($card-scheme, divider);
        padding: 10px 0 11px;
    }
    .section-header__divider {
        width: 100%;
        height: 2px;
        background: map_get($card-scheme, divider);
        margin-top: 8px;
    }
}
