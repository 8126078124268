/* You can add global styles to this file, and also import other style files */

@import 'scss/bootstrap';
@import 'scss/style';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

a {
	cursor: pointer;
}
// Country Code

//input:not(.country-search){
//	bottom: 3px;
//	left: 10px;
//}
//
//.country-list-button{
//	font-size: 0.8rem!important;
//}

//.mat-mdc-menu-panel.mat-mdc-menu-panel {
//	top: 300px;
//	left: -15px
//}

//.mat-menu-content:not(:empty){
//	max-height:250px;
//}
//
//.mat-tab-body-wrapper{
//	max-width: 980px;
//}
//
//.mat-tab-body-content{
//	overflow: hidden !important;
//}

.token-countdown {
	font-size: 16px;
	line-height: 19px;
	padding: calc(17px * .5) 16px;
}

.toast-container {
	pointer-events: none;
	position: fixed;
	z-index: 100000!important;
}

