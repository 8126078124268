/*
// .post
*/
@import '../variables';
@import '../mixins/card';
@import '../mixins/direction';
@import '../mixins/breakpoints';
@import '../mixins/scheme';


$local-padding: 48px;


.post {}
.post__body {
    padding: $local-padding;
}

// pagination
.post__pagination {
    border-top: 1px solid map_get($card-scheme, divider);
    margin: -28px $local-padding 0;
    padding-top: 20px;
    padding-bottom: $local-padding;
}
.post__pagination-title {
    font-size: 12px;
    color: map_get($card-scheme, muted);
    text-transform: uppercase;
    margin-bottom: 5px;
}
.post__pagination-list {
    font-size: 15px;

    ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: -3px;
    }
    li {
        margin: 3px;
    }
}
.post__pagination-link {
    padding: 2px 8px;
    display: block;
    border-radius: 1.5px;
    transition: background .2s;

    @include define-button-state($pagination-scheme, normal);

    &:hover {
        @include define-button-state($pagination-scheme, hover);
    }
    &:active {
        transition-duration: 0s;

        @include define-button-state($pagination-scheme, active);
    }
}
.post__pagination-link--current {
    cursor: default;

    &,
    &:hover,
    &:active {
        @include define-button-state($pagination-scheme, current);
    }
}

// footer
.post__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 $local-padding $local-padding;
    margin-top: -24px;
}
.post__tags {
    @include direction {
        #{$margin-inline-end}: 32px;
    }
}
.post__share-links {
    padding-bottom: 1px;
}

// author
.post__author {
    display: flex;
    align-items: center;
    margin: -24px $local-padding 0;
    padding: 24px 0 $local-padding;
    border-top: 1px solid map_get($card-scheme, divider);

    img {
        display: block;
    }
}
.post__author-avatar {
    overflow: hidden;
    flex-shrink: 0;
    border-radius: 2px;

    @include direction {
        #{$margin-inline-end}: 20px;
    }
}
.post__author-name {
    font-weight: $font-weight-medium;
}
.post__author-about {
    font-size: 15px;
    margin-top: 4px;
}


@include media-breakpoint-down(sm) {
    $local-padding: 32px;

    .post__body {
        padding: $local-padding;
    }
    .post__pagination {
        margin: 0 $local-padding 0;
    }
    .post__pagination {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .post__footer {
        flex-direction: column;
        align-items: center;
        padding: 0 $local-padding 24px;
    }
    .post__tags {
        @include direction {
            #{$margin-inline-end}: 0;
        }

        .tags__list {
            justify-content: center;
        }
    }
    .post__share-links {
        margin-top: 20px;
        padding-bottom: 0;
    }
    .post__author {
        margin: 0 $local-padding 0;
        padding: $local-padding 0 $local-padding;
        flex-direction: column;
    }
    .post__author-avatar {
        border-radius: 50%;

        @include direction {
            #{$margin-inline-end}: 0;
        }
    }
    .post__author-info {
        text-align: center;
        margin-top: 16px;
    }
    .post__author-about {
        margin-top: 6px;
    }
}
@include media-breakpoint-down(xs) {
    $local-padding: 24px;

    .post__body {
        padding: $local-padding;
    }
    .post__pagination {
        margin: 0 $local-padding 0;
    }
    .post__author {
        margin: 0 $local-padding 0;
        padding: $local-padding 0 $local-padding;
    }
}
