/*
// .profile-card
*/
@import '../variables';


.profile-card {}
.profile-card__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.profile-card__avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 16px;

    img {
        border-radius: 50%;
		width: 100%;
        max-width: 100%;
		height: 100%;
		object-fit: cover;
    }
}
.profile-card__name {
    font-weight: $font-weight-medium;
    line-height: 20px;
}
.profile-card__email {
    font-size: 15px;
    margin-bottom: 24px;
}

.profile-label {
	font-size: 15px;
	margin-bottom: 24px;
}

