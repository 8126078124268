/*
// .form
*/
@import '../variables';
@import '../mixins/direction';


.form {}
.form-check {
    @include direction {
        #{$padding-inline-start}: 1.5rem;
    }
}
.form-check-input {
    position: absolute;
    margin-top: .1875rem;

    @include direction {
        #{$margin-inline-start}: -1.5rem;
    }
}

.readonly {
	cursor: not-allowed; // Change the cursor to indicate it's not interactive
	pointer-events: none; // Disable pointer events, making it unclickable
	user-select: none; // Prevent text selection
	opacity: 0.7; // Optionally reduce opacity to visually indicate it's disabled
}
