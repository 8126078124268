/*
// .widget-search
*/
@import '../variables';


$local-height: 52px;
$local-line-height: 21px;


.widget-search {}
.widget-search__form {
    display: flex;
    position: relative;
    z-index: 0;
}
.widget-search__input {
    flex-grow: 1;
    appearance: none;
    border: none;
    background: none;
    padding: (($local-height - $local-line-height) * .5) 24px;
    font-family: inherit;
    font-size: 16px;
    line-height: $local-line-height;

    &:focus {
        outline: none;
    }
}
.widget-search__button {
    flex-shrink: 0;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $widget-search-button-icon-color;
    fill: currentColor;
    width: $local-height;
    height: $local-height;
    padding: 0;

    &:focus {
        outline: none;
    }
    &:hover {
        color: $widget-search-button-hover-icon-color;
        background: $widget-search-button-hover-bg-color;
    }
    &:active {
        color: $widget-search-button-active-icon-color;
        background: $widget-search-button-active-bg-color;
    }
}
.widget-search__field {
    position: absolute;
    display: block;
    content: '';
    z-index: -1;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 1px;
    transition: box-shadow .2s;
}

.widget-search__form:hover .widget-search__field {
    box-shadow: $widget-search-input-hover-shadow;
}
.widget-search__input:focus ~ .widget-search__field {
    box-shadow: $widget-search-input-focus-shadow;
}
