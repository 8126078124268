/*
// .filter-range
*/
@import '../variables';


.filter-range {}
.filter-range__slider {
    margin-top: 6px;
}
.filter-range__title {
    padding-top: 2px;
    font-size: 14px;
}
.filter-range__title-button {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
