.tab-header {
	display: flex;
	overflow: hidden;
	position: relative;
	flex-shrink: 0;
	border-bottom: 1px solid #0000001f;
	justify-content: center;

	.tab-label {
		height: 48px;
		padding: 0 24px;
		border: none;
		box-sizing: border-box;
		opacity: .6;
		min-width: 160px;
		box-shadow: none;
		background: transparent;
		color: #000000de;
		font-weight: 500;
	}

	.tab-link-active {
		border-bottom: 3px solid #0018A8;
		color: black;
	}
}
